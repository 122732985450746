import React from 'react';
import { Link } from "react-router-dom";
import homeimage from './assets/images/homepage-image.jpg';
import moon from './assets/images/moon.svg';
import perry from './assets/images/perry-thumb.jpg';
import archie from './assets/images/archie-thumb.jpg';
import chilko from './assets/images/chilko-thumb.jpg';
import dakota from './assets/images/dakota-thumb.jpg';
import fleur from './assets/images/fleur-thumb.jpg';
import maggie from './assets/images/maggie-thumb.jpg';
import gibson from './assets/images/gibson-thumb.jpg';
import juno from './assets/images/juno-thumb.jpg';
import mimosa from './assets/images/mimosa-thumb.jpg';
import swift from './assets/images/swift-thumb.jpg';
import scarlett from './assets/images/scarlett-thumb.jpg';
import echo from './assets/images/echo-thumb.jpg';
import ckc from './assets/images/luna-cert.jpg';
import silverckc from './assets/images/silverspring-ckc.jpg';

class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="homepage-container">
        <div className="homepage content">
          <div className="main">
            <img src={homeimage} alt="LunaNova" className="feature-image" />
            <h1>Welcome to LunaNova</h1>
            <p className="headline">At LunaNova, we strive to breed loving family pets.<br />We are a small, home-based, family-oriented breeder located on a beautiful acre of property in Cambridge, Ontario.</p>
            <p>LunaNova is seriously committed to breeding healthy, well-tempered, intelligent puppies.</p>
            <p>Our pups are born in a <i>puppy room</i> on the main level of our house, and raised in our busy, bustling household with our children and other dogs. At our house, there is little distinction between the kids and the dogs, as they are all important family members!</p>
            <p>Our puppies come from our home-bred Canadian-Championed Mothers, and Established Canadian and/or American-Championed Fathers, all who descend from multiple generations of Championship bloodlines! Our adult dogs have comprehensive health clearances, and are known for the health, temperament, structure and trainability of their offspring.</p>
            <p><Link to="/about">Learn more about LunaNova</Link></p>
            <blockquote>I am the caretaker not only of the individual dog, but of the reputation and welfare of the breed as a whole.<br /><br />It is incumbent upon me to set the example of each dog being individually cherished, but only the physically and temperamentally sound being bred.<br /><br />I accept the premise that if I breed a litter, those dogs are my responsibility throughout their entire lifetime.<br /><br />
              - Preservation Breeders' Oath</blockquote>
            <div className="divider">
              <img src={moon} alt="LunaNova moon" className="moon" />
            </div>
            <h2>About Portuguese Water Dogs</h2>
            <p>Overall, Portuguese Water Dogs are a spirited, robust swimmer and diver; loyal and alert. The Portuguese Water Dog is intelligent and quite easy to train. He learns new commands quickly at an above average rate. Portuguese Water Dogs are social and interactive dogs, known for their athleticism and high energy. According to the Canadian Kennel Club, Portuguese Water Dogs require daily vigorous exercise and respond well to training.</p>
            <p>The Portuguese Water Dog is a hypoallergenic breed. They grow and lose hair much like a human does, and require frequent grooming. However, a very small percentage of people with severe allergies will still have a reaction to this breed.  So it is important, if anyone in your family suffers from dog allergies, to visit.  In order to kiss, hug and pet the dogs, to make sure that the PWD does not cause an allergic reaction.</p>
            <table className="simple-table styled-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Height</th>
                  <th>Weight</th>
                </tr>
              </thead>
              <tbody>
                <tr className="male">
                  <td><strong>Male</strong></td>
                  <td>19.5 - 22.5"</td>
                  <td>42 - 60 lbs</td>
                </tr>
                <tr className="female">
                  <td><strong>Female</strong></td>
                  <td>16.5 - 20.5"</td>
                  <td>35 - 50 lbs</td>
                </tr>
              </tbody>
            </table>
            <p><a href="https://www.ckc.ca/en/Choosing-a-Dog/Choosing-a-Breed/Working-Dogs/Portuguese-Water-Dog" target="_blank" rel="noopener noreferrer">Learn more about Portuguese Water Dogs</a></p>
          </div>
          <div className="side">
            <div className="section">
              <h2>Puppies Available</h2>
              <p>We will have puppies ready for forever homes Fall 2024. Click below to learn more!</p>
              <Link to="/adoptions" className="call-to-action big shadow">
                <span>Adopt a Puppy</span>
              </Link>
              <p>If you send us an application or email, and do not get a reply, please check your spam folder. As we ALWAYS reply! Unfortunately sometimes our replies do end up in peoples junk folders!</p>
            </div>
            <div className="section">
              <h2>More about PWDs</h2>
              <p>We believe that Portuguese Water Dogs are a unique and special breed.</p>
              <p>If you are interested in a PWD, we ask that you <a href="http://pwdcc.org/" target="_blank" rel="noopener noreferrer">do some research on the breed</a>.</p>
            </div>
            <div className="section social">
              <h2>More LunaNova</h2>
              <p>Check out our past litters on <a href="https://www.youtube.com/user/LUNANOVAPWDS" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i> YouTube</a><br />
                Follow us on <a href="https://www.instagram.com/lunanovapwds/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i> Instagram</a></p>
            </div>
            <div className="section">
              <h2>CKC Registered</h2>
              <p>LunaNova and Silverspring are registered through the Canadian Kennel Club.</p>
              <img src={ckc} alt="LunaNova Canadian Kennel Club certificate" className="thumb" />
              <img src={silverckc} alt="Silverspring Canadian Kennel Club certificate" className="thumb" />
            </div>
          </div>
        </div>
        <div className="homepage-footer content">
          <div className="divider">
            <img src={moon} alt="LunaNova moon" className="moon" />
          </div>
          <h2 className="centered">The Dogs</h2>
          <div className="dog-features">
            <Link to="/dogs/archie" className="dog">
              <img src={archie} alt="LunaNova Archie" />
              <span>Archie</span>
            </Link>
            <Link to="/dogs/chilko" className="dog">
              <img src={chilko} alt="LunaNova Chilko" />
              <span>Chilko</span>
            </Link>
            <Link to="/dogs/dakota" className="dog">
              <img src={dakota} alt="LunaNova Dakota" />
              <span className="female">Dakota</span>
            </Link>
            <Link to="/dogs/echo" className="dog">
              <img src={echo} alt="LunaNova Echo" />
              <span className="female">Echo</span>
            </Link>
            <Link to="/dogs/fleur" className="dog">
              <img src={fleur} alt="LunaNova Fleur" />
              <span className="female">Fleur</span>
            </Link>
            <Link to="/dogs/gibson" className="dog">
              <img src={gibson} alt="LunaNova Gibson" />
              <span className="female">Gibson</span>
            </Link>
            <Link to="/dogs/juno" className="dog">
              <img src={juno} alt="LunaNova Juno" />
              <span className="female">Juno</span>
            </Link>
            <Link to="/dogs/maggie" className="dog">
              <img src={maggie} alt="LunaNova Maggie" />
              <span className="female">Maggie</span>
            </Link>
            <Link to="/dogs/mimosa" className="dog">
              <img src={mimosa} alt="LunaNova Mimosa" />
              <span className="female">Mimosa</span>
            </Link>
            <Link to="/dogs/perry" className="dog">
              <img src={perry} alt="LunaNova Perry" />
              <span>Perry</span>
            </Link>
            <Link to="/dogs/scarlett" className="dog">
              <img src={scarlett} alt="LunaNova Scarlett" />
              <span className="female">Scarlett</span>
            </Link>
            <Link to="/dogs/swift" className="dog">
              <img src={swift} alt="LunaNova Swift" />
              <span className="female">Swift</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
